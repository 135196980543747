import { transfer } from "./request";

/**
 * 电子标签信息
 * @returns 
 */
export function tagOptions(){
    return transfer({
        method: 'get',
        url: '/hardware/tag/options',
    })
}
export function tagPage(params){
    return transfer({
        method: 'get',
        url: '/hardware/tag/page',
        params
    })
}
export function tagUpdate(data){
    return transfer({
        method: 'post',
        url: '/hardware/tag/update',
        data
    })
}
export function tagClearOffline(minutes){
    return transfer({
        method: 'post',
        url: '/hardware/tag/clearOffline?minutes='+minutes,
    })
}
export function tagSetTypeByPrefix(data){
    return transfer({
        method: 'post',
        url: '/hardware/tag/setTypeByPrefix',
        data
    })
}
/**
 * 
 * 监控区域
 * @returns 
 */
export function regionPage(params){
    return transfer({
        method: 'get',
        url: '/hardware/region/page',
        params
    })
}
export function regionStatus(id){
    return transfer({
        method: 'get',
        url: '/hardware/region/status?id='+id,
    })
}
export function regionAdd(data){
    return transfer({
        method: 'post',
        url: '/hardware/region/add',
        data
    })
}
export function regionUpdate(data){
    return transfer({
        method: 'post',
        url: '/hardware/region/update',
        data
    })
}
export function regionDel(id){
    return transfer({
        method: 'post',
        url: '/hardware/region/del?id='+id,
    })
}
/**
 * 
 * 感知设备
 * @returns 
 */
export function deviceOptions(){
    return transfer({
        method: 'get',
        url: '/hardware/device/options',
    })
}
export function devicePage(params){
    return transfer({
        method: 'get',
        url: '/hardware/device/page',
        params
    })
}
export function deviceStatus(id){
    return transfer({
        method: 'get',
        url: '/hardware/device/status?id='+id,
        // params
    })
}
export function deviceUpdate(data){
    return transfer({
        method: 'post',
        url: '/hardware/device/update',
        data
    })
}
/**
 * 
 * 物品匹配规则
 * @returns 
 */
export function itemRulePage(params){
    return transfer({
        method: 'get',
        url: '/hardware/item/rule/page',
        params
    })
}
export function itemRuleAdd(data){
    return transfer({
        method: 'post',
        url: '/hardware/item/rule/add',
        data
    })
}
export function itemRuleUpdate(data){
    return transfer({
        method: 'post',
        url: '/hardware/item/rule/update',
        data
    })
}
export function itemRuleDel(id){
    return transfer({
        method: 'post',
        url: '/hardware/item/rule/del?id='+id,
    })
}
/**
 * 
 * 设备维护日志
 */
export function deviceLogPage(params){
    return transfer({
        method: 'get',
        url: '/hardware/device/log/page',
        params
    })
}
export function deviceLogAdd(data){
    return transfer({
        method: 'post',
        url: '/hardware/device/log/add',
        data
    })
}