import { createStore } from 'vuex'
import tci from './report/tci.js'
import medicalStaff from './process/medicalStaff.js'
import patient from './process/patient.js'
import process from './process/process.js'
import reportTemplate from './report/template.js'
import hardware from './hardware/index.js'
import database from './database/index.js'
import userInfo from './user/userInfo.js'

export default createStore({
  modules: {
    medicalStaff,
    patient,
    process,
    tci,
    reportTemplate,
    hardware,
    database,
    userInfo
  }
})
