import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    // redirect:'/login',
    component:()=>import('../views/home.vue'),
    children:[
      // {
      //   path: 'index',
      //   name: '首页',
      //   component:()=>import('../views/index.vue'),
      // },
      {
        path: 'label_list',
        name: '标签状态',
        component:()=>import('../views/hardware/label_list.vue'),
      },
      {
        path: 'equipment_list',
        name: '监控设备',
        component:()=>import('../views/hardware/equipment_list.vue'),
      },
      {
        path: 'perception_list',
        name: '感知设备',
        component:()=>import('../views/hardware/perception_list.vue'),
      },
      {
        path: 'used_thing_list',
        name: '物品匹配规则',
        component:()=>import('../views/hardware/used_thing_list.vue'),
      },
      {
        path: 'my_information',
        name: '我的信息',
        component:()=>import('../views/process/my_information.vue'),
      },
      {
        path: 'medical_staff',
        name: '医护人员',
        component:()=>import('../views/process/medical_staff.vue'),
      },
      {
        path: 'patient_list',
        name: '患者信息',
        component:()=>import('../views/process/patient_list.vue'),
      },
      {
        path: 'treatment_process',
        name: '急救流程',
        component:()=>import('../views/process/treatment_process.vue'),
      },
      {
        path: 'quality_control',
        name: '时间质控',
        component:()=>import('../views/report/quality_control.vue'),
      },
      {
        path: 'report_template',
        name: '报告模板',
        component:()=>import('../views/report/report_template.vue'),
      },
      {
        path: 'assessment_criteria',
        name: '考核标准',
        component:()=>import('../views/report/assessment_criteria.vue'),
      },
      {
        path: 'process_report',
        name: '流程报告',
        component:()=>import('../views/report/process_report.vue'),
      },
      {
        path: 'create_report/:id',
        name: '生成报告',
        component:()=>import('../views/report/create_report.vue'),
      },
      {
        path: 'process_details/:id',
        name: '流程报告_详情',
        component:()=>import('../views/report/process_details.vue'),
      },
      {
        path: 'overview',
        name: '统计',
        component:()=>import('../views/census/overview.vue'),
      },
      {
        path: 'statistical_template',
        name: '统计模板',
        component:()=>import('../views/census/statistical_template.vue'),
      },
      {
        path: 'summary',
        name: '汇总统计',
        component:()=>import('../views/census/summary.vue'),
      },
      {
        path: 'examine',
        name: '单项考核',
        component:()=>import('../views/census/examine.vue'),
      },
      {
        path: 'user_manage',
        name: '用户管理',
        component:()=>import('../views/user/user_manage.vue'),
      },
      {
        path: 'role_manage',
        name: '角色管理',
        component:()=>import('../views/user/role_manage.vue'),
      },
      {
        path: 'permissions',
        name: '权限管理',
        component:()=>import('../views/user/permissions.vue'),
      },
      {
        path: 'user_details/:userId',
        name: '用户信息',
        component: () => import('../views/user/user_details.vue')
      },
      {
        path: 'operate_log',
        name: '操作日志',
        component: () => import('../views/user/operate_log.vue')
      },
      {
        path: 'database',
        name: '数据库备份',
        component:()=>import('../views/database/database.vue'),
      },
      {
        path: 'instructions',
        name: '操作说明',
        component:()=>import('../views/instructions.vue'),
      },
      {
        path: 'about',
        name: '关于',
        component:()=>import('../views/about.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: '登录',
    component:()=>import('../views/login.vue'),
  },
  {
    path: '/register',
    name: '注册',
    component:()=>import('../views/register.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
