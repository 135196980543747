import  {regionStatus}  from "@/api/hardware";
export default {
    namespaced:true,
    state: {
        ruleDate:[],
        deviceDate:[]
    },
    mutations: {
        itemRuleDate (state,data){
            state.ruleDate = data
        },
        deviceInfo (state,data){
            state.deviceDate = data
        },
    },
    actions: {
        
    },
    getters: {
    },
}