import axios from "axios";
import { ElMessage } from "element-plus";
import router from '../router'

export function transfer(config) {
   
    const instance = axios.create({
        baseURL: '/api', // api的base_url
        timeout: 30000,// 请求超时时间
        headers: { 
          'content-type': 'application/json;charset=UTF-8',
        },
        xsrfCookieName: 'XSRF-TOKEN', // 默认值
        xsrfHeaderName: 'X-XSRF-TOKEN', // 默认值
    })

    let getCookie = function (name, token) {
      var value = '; ' + token
      var parts = value.split('; ' + name + '=')
      if (parts.length === 2) return parts.pop().split(';').shift()
    }

    // 拦截请求
    instance.interceptors.request.use(config => {
      let token = document.cookie;
      if(token&&config.method === 'post'){
        config.headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN',token);
      }
        return config
    },error => {
      console.log(error);
        return Promise.reject(error)
    })

    // 拦截响应
    instance.interceptors.response.use(res => {
            //可以在此处拦截接口错误
        return res
        }, error => {
            console.log(error);
            if (error.response) {
                if (error.response.status === 400) {
                  // if (error.response.data.data.includes("请登陆后再访问")) {}
                  if (error.response.data.data.length !== 0) {
                    error.response.data.data.forEach(item => {
                      ElMessage.error(item.defaultMessage)
                    });
                  }else{
                    ElMessage.error(error.response.data.message)
                  }
                } else if(error.response.status === 401){
                  ElMessage.error(error.response.data.data)
                  router.push({path:'/login'})
                }
                return Promise.reject(error)
            }else {
                // 处理超时的情况
                let config = error.config
                // If config does not exist or the retry option is not set, reject
                if(!config || !config.retry) return Promise.reject(error)
            
                // Set the variable for keeping track of the retry count
                config.__retryCount = config.__retryCount || 0
            
                // Check if we've maxed out the total number of retries
                if(config.__retryCount >= config.retry) {
                  // Reject with the error
                  return Promise.reject(error)
                }
            
                // Increase the retry count
                config.__retryCount += 1
            
                // Create new promise to handle exponential backoff
                let backoff = new Promise(function(resolve) {
                  setTimeout(function() {
                    resolve()
                  }, config.retryDelay || 1)
                })
            
                // Return the promise in which recalls axios to retry the request
                return backoff.then(function() {
                  return service(config)
                })
              }
    })

    return instance(config);
}
  