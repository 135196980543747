import { transfer } from "./request";

export function verifyCodeBase64(type){
    return transfer({
        method: 'get',
        url: '/sys/verifyCode/base64?type='+type ,
    })
}
export function login(data){
    return transfer({
        method: 'post',
        url: '/sys/user/login' ,
        data
    })
}
export function regAdmin(data){
    return transfer({
        method: 'post',
        url: '/sys/user/reg' ,
        data
    })
}
export function logOut(){
    return transfer({
        method: 'post',
        url: '/sys/user/logout' ,
    })
}
export function loginToken(){
    return transfer({
        method: 'post',
        url: '/sys/user/token' ,
    })
}
export function userInfo(){
    return transfer({
        method: 'get',
        url: '/sys/user/userInfoFind' ,
    })
}
export function changePassword(oldPass,newPass){
    return transfer({
        method: 'post',
        url: '/sys/user/changePwd?oldPass=' + oldPass + '&newPass=' + newPass,
    })
}
/**
 * 用户角色
 */
export function userHoldRoleList(userId){
    return transfer({
        method: 'get',
        url: '/sys/user/role/list?userId='+userId ,
    })
}
export function roleConfig(data){
    return transfer({
        method: 'post',
        url: '/sys/user/role/config' ,
        data
    })
}
/**
 * 权限接口
 */
export function permissionPage(params){
    return transfer({
        method: 'get',
        url: '/sys/permission/page',
        params
    })
}
export function permissionGroupPage(){
    return transfer({
        method: 'get',
        url: '/sys/permission/group',
    })
}
/**
 * 用户管理
 */
export function userPage(params){
    return transfer({
        method: 'get',
        url: '/sys/user/admin/page',
        params
    })
}
export function createAdmin(data){
    return transfer({
        method: 'post',
        url: '/sys/user/admin/create' ,
        data
    })
}
export function resetPassword(data){
    return transfer({
        method: 'post',
        url: '/sys/user/admin/resetPassword' ,
        data
    })
}
export function lockAdmin(userId){
    return transfer({
        method: 'post',
        url: '/sys/user/admin/lock?userId='+userId ,
    })
}
export function userInfoFind(userId){
    return transfer({
        method: 'get',
        url: '/sys/user/admin/userInfoFind?userId='+userId ,
    })
}
export function adminUserInfoUpdate(userId,data){
    return transfer({
        method: 'post',
        url: '/sys/user/admin/userInfoUpdate?userId='+userId ,
        data
    })
}
/**
 * 角色管理
 */
export function rolePage(params){
    return transfer({
        method: 'get',
        url: '/sys/role/page',
        params
    })
}
export function roleAdd(data){
    return transfer({
        method: 'post',
        url: '/sys/role/add' ,
        data
    })
}
export function roleUpdate(roleId,data){
    return transfer({
        method: 'post',
        url: '/sys/role/update?roleId='+roleId ,
        data
    })
}
export function roleDel(data){
    return transfer({
        method: 'post',
        url: '/sys/role/del' ,
        data
    })
}
/**
 * 角色权限
 */
export function permissionList(roleId){
    return transfer({
        method: 'get',
        url: '/sys/role/permission/list?roleId='+roleId ,
    })
}
export function configPermission(data){
    return transfer({
        method: 'post',
        url: '/sys/role/permission/config',
        data
    })
}
/**
 * 操作日志
 */
export function adminLogOptions(params){
    return transfer({
        method: 'get',
        url: '/sys/user/admin/log/options',
        params
    })
}
export function adminLogPage(params){
    return transfer({
        method: 'get',
        url: '/sys/user/admin/log/page',
        params
    })
}