import {  } from "@/api/database";
export default {
    namespaced:true,
    state: {
        options:[],
        oldStatus:undefined
    },
    mutations: {
        logOptiosData(state,[data,old]){
            state.options = data
            state.oldStatus = old
        }
    },
    actions: {
        
    },
    getters: {
    },
}