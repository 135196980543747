import { transfer } from "./request";

export function databaseStatus(){
    return transfer({
        method: 'get',
        url: '/database/status',
    })
}
export function getDatabaseList(){
    return transfer({
        method: 'get',
        url: '/database/list',
    })
}
export function databaseDownload(filename){
    return transfer({
        method: 'get',
        url: '/database/download?filename='+filename,
    })
}
export function databaseLogOptions(params){
    return transfer({
        method: 'get',
        url: '/database/log/options',
        params
    })
}
export function databaseLogPage(params){
        return transfer({
            method: 'get',
            url: '/database/log/page',
            params
        })
    }
export function databaseBackup(gzip){
    return transfer({
        method: 'post',
        url: '/database/backup?gzip='+gzip,
    })
}
export function databaseRecover(filename){
    return transfer({
        method: 'post',
        url: '/database/recover?filename='+filename,
    })
}
export function databaseDel(filename){
    return transfer({
        method: 'post',
        url: '/database/del?filename='+filename,
    })
}