import { loginToken,userInfo } from "@/api/user";
export default {
    namespaced:true,
    state: {
        userInfo:(localStorage.getItem('userData') && JSON.parse(localStorage.getItem
        ('userData'))) || {},
    },
    mutations: {
        userInfo (state,data){
            //uInfo : 传入的值
            state.userInfo = data
        },
    },
    actions: {
        getUserInfo (context){
            return userInfo()
            .then(res => {
                return res.data
            })
        },
        getUserToken (context){
            return loginToken()
            .then(res => {
                context.commit('userInfo',res.data);
                return res.data
            })
        }
    },
    getters: {
    },
}