import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/js/DateEnhancement'
// import echarts from './assets/js/echarts';
import * as echarts from 'echarts'
import JsonViewer from 'vue-json-viewer'
import "./assets/font/iconfont.css";

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$echarts = echarts

app.use(store).use(router).use(ElementPlus,{locale}).use(JsonViewer).mount('#app')
