export default {
    namespaced:true,
    state: {
        tableData:[]
    },
    mutations: {
        addRowData (state,data){
            state.tableData.push(data)
        },
    },
    actions: {
    },
    getters: {
    },
}