export default {
    namespaced:true,
    state: {
        optionsData:[],
        doctorsData:[],
    },
    mutations: {
        optionsList (state,data){
            state.optionsData = data
        },
        doctorsOptionsList (state,data){
            state.doctorsData = data
        },
    },
    actions: {
    },
    getters: {
    },
}