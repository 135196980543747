<template>
  <router-view/>
</template>

<style>
body,html{
  margin: 0;
  padding: 0;
  background: #EDF0F6;
  height: 100%;
}
a {
  text-decoration: none;
  color: #606266;
}
.el-menu {
  border-right: none !important;
}
.el-container{
  height: 100%;
}
.el-main{
  padding: 10px !important;
}
.el-card__header {
  padding: 10px 20px !important;
}
.operate_bar{
  margin-bottom: 10px;
}
.operate_bar .el-card__body{
  padding:20px 20px 10px !important;
}
.el-page-header__content {
  font-size: 16px !important;
}
.drawer-footer{
  margin-top: 40px;
  text-align: right;
}
.pagination-bar {
  margin-top: 20px;
  justify-content: center;
} 
.function-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
