export default {
    namespaced:true,
    state: {
        patienData:[]
    },
    mutations: {
        patienInfoData(state,data){
            state.patienData = data
        },
    },
    actions: {
    },
    getters: {
    },
}